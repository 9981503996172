<style scoped lang="scss">
@import "~@/styles/_colors.scss";

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: green;
}
.validation-failure {
  color: red;
}

.content-qr {
  padding-top: 80px;
}

.content-qr__video {
  max-width: 500px;
  position: relative;
  width: calc(100% - 50px);
  margin: 0px auto;
  margin-top: 25px;
}
.v-progress-circular {
  height: 32px;
  width: 32px;
  position: absolute;
  top: calc(50% - 32px / 2);
  left: calc(50% - 32px / 2);
}
#qrcode {
  max-height: 350px !important;
  overflow: hidden !important;
}
.pull-up {
  padding-top: 50px;
}
.content-qr__error {
  max-width: 500px;
  position: relative;
  margin: 0px auto;
  text-align: center;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.camera-corners {
  width: 60px;
  height: 60px;
  position: absolute;
}
.content-qr__video-coner-left {
  left: -4px;
  top: -4px;
  border-left: 4px solid $primary;
  border-top: 4px solid $primary;
}

.content-qr__video-coner-right {
  right: -4px;
  top: -4px;
  border-right: 3px solid $primary;
  border-top: 3px solid $primary;
}
.content-qr__video-coner-bottom-right {
  bottom: -4px;
  border-bottom: 3px solid $primary;
  border-right: 3px solid $primary;
  right: -4px;
}

.content-qr__video-coner-bottom-left {
  bottom: -4px;
  left: -4px;
  border-bottom: 3px solid $primary;
  border-left: 3px solid $primary;
}

.hide-mask {
  opacity: 0;
}
</style>

<template>
  <div>
    <div v-if="msgError" class="content-qr__error">{{ msgError }}</div>
    <div v-show="!error" class="content-qr__video">
      <div
        class="camera-corners content-qr__video-coner-left"
        :style="{'border-color': (isValid ? 'green': '#ED2236') }"
      ></div>
      <div
        class="camera-corners content-qr__video-coner-right"
        :style="{'border-color': (isValid ? 'green': '#ED2236') }"
      ></div>
      <div
        class="camera-corners content-qr__video-coner-bottom-left"
        :style="{'border-color': (isValid ? 'green': '#ED2236') }"
      ></div>
      <div
        class="camera-corners content-qr__video-coner-bottom-right"
        :style="{'border-color': (isValid ? 'green': '#ED2236') }"
      ></div>

      <qrcode-stream id="qrcode" :camera="camera" @decode="onDecode" @init="onInit">
        <div v-if="validationSuccess" class="validation-success">{{ $t('lang.QRcode.ready') }}</div>
        <div v-if="validationFailure" class="validation-failure">{{ $t('lang.QRcode.invalidQR') }}</div>
        <div v-if="validationPending" class="validation-pending">
          <v-container v-if="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-container>
          <p v-else>{{ $t('lang.QRcode.scaning') }}</p>
        </div>
      </qrcode-stream>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  name: "QrCamera",
  components: { QrcodeStream },
  props: {
    turnCamera: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },
    validationSuccess() {
      return this.isValid === true;
    },
    validationFailure() {
      return this.isValid === false;
    }
  },
  data() {
    return {
      display: this.open,
      justMounted: false,
      data: null,
      loading: true,
      error: false,
      msgError: "",
      isValid: undefined,
      camera: "off",
      result: null
    };
  },
  watch: {
    turnCamera(toggle) {
      if (toggle) return this.turnCameraOn();
      return this.turnCameraOff();
    }
  },
  methods: {
    onInit(promise) {
      this.loading = true;
      promise
        .then(() => {
          this.error = false;
          this.msgError = false;
          this.loading = false;
          this.resetValidationState();
        })
        .catch(error => {
          this.error = true;
          this.loading = false;
          if (error.name === "NotAllowedError") {
            console.log("user denied camera access permisson");
            this.msgError = "Es necesario que concedas permisos a la camara del dispositivo";
          } else if (error.name === "NotFoundError") {
            console.log("no suitable camera device installed");
            this.msgError = "No se encontro camera disponible";
          } else if (error.name === "NotSupportedError") {
            console.log("page is not served over HTTPS (or localhost)");
          } else if (error.name === "NotReadableError") {
            console.log("maybe camera is already in use");
            this.msgError = "La camara esta siendo utilizada por otra aplicacion";
          } else if (error.name === "OverconstrainedError") {
            console.log("did you requested the front camera although there is none?");
          } else if (error.name === "StreamApiNotSupportedError") {
            console.log("browser seems to be lacking features");
            this.msgError = "Tu dispositivo no soporta esta caracteristica";
          } else {
            this.msgError = "Ha ocurrido un error interno en la aplicacion.";
          }
        });
    },
    resetValidationState() {
      this.isValid = undefined;
    },
    onDecode(content = "") {
      console.log('contenidoqr', content);
      this.result = content;
      this.isValid = content.includes("#geochef");
      const [id, notificationId] = content.replace("#geochef", "").split(" ");
      this.$emit("order", { id, notificationId });
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    turnCameraOff() {
      this.camera = "off";
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms);
      });
    },
    validData() {
      return true;
    }
  }
};
</script>
