<template src="./template.html"></template>
<style scoped lang="scss">
@import "./style.scss";
</style>

<script>
export default {
  name: "SearchUserForm",
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      users: [],
      items: [],
      states: [],
      search: null,
      select: null,
      confirmed: false,
      processing: false
    };
  },
  watch: {
    search(val) {
      const result = val && val !== this.select && this.querySelections(val);
      return result;
    },
    open(val) {
      if (!val) {
        this.loading = false;
        this.items = [];
        this.states = [];
        this.search = null;
        this.select = null;
        this.processing = false;
        this.confirmed = false;
      }
    }
  },
  methods: {
    findUserByEmail(email) {
      return this.users.find(user => user.email === email);
    },
    handleOrder() {
      if (this.select) {
        const [, email] = this.select.split("/");
        const user = this.findUserByEmail(email);
        this.$emit("order", user);
      } else {
        this.$emit("orderAnonymus");
      }
    },
    handleInput() {
      if (!this.select) this.confirmed = false;
    },
    handleSelect() {
      this.loading = false;
      this.confirmed = true;
    },
    resolveQuery(query) {
      return query.empty === false;
    },
    async querySelections(v) {
      this.loading = true;
      const db = window.firebase.firestore();
      const UserRef = db.collection("Users");
      const names = [];
      const emails = [];
      const keywords = v.toLowerCase();
      keywords.split(" ").forEach(word => {
        word = word
          .replace("/", "")
          .replace(".", "")
          .replace("@", "");
        if (word) {
          names.push(UserRef.where(`_name.${word}`, "==", true).get());
          emails.push(UserRef.where(`_email.${word}`, "==", true).get());
        }
      });
      const queries = [...names, ...emails];
      const resolves = await Promise.all(queries);
      const match = resolves.find(this.resolveQuery);
      if (!match) {
        this.loading = false;
        this.confirmed = false;
        return;
      }
      const result = [];
      match.docs.forEach(doc => {
        const { name, email, id, notificationId = "" } = doc.data();
        result.push({
          name,
          email,
          id,
          notificationId
        });
      });
      this.loading = false;
      this.states = result.map(doc => `${doc.name}/${doc.email}`);
      this.items = this.states.filter(e => (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1);
      result.forEach(item => {
        const notFound = !this.users.find(user => user.id === item.id);
        if (notFound) this.users.push(item);
      });
    }
  }
};
</script>