<style scoped lang="scss">
@import "~@/styles/_colors.scss";

::v-deep .modal-order-tabs {
  background: white;
  border-radius: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 50px;
  padding-bottom: 30px;
}

::v-deep .modal-order-tabs--fullscreen {
  background: white;
  border-radius: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 50px;
}

.btn-cancel {
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  background: #fff !important;
  color: $secondary !important;
  border: 1px solid $secondary !important;
  font-family: Gibson-Bold;
  text-transform: uppercase;
}
</style>

<template>
  <v-dialog
    :transition="fullscreen ? 'dialog-bottom-transition': 'scale-transition'"
    :fullscreen="fullscreen"
    :hide-overlay="fullscreen"
    max-width="500"
    persistent
    v-model="displayModal"
    :content-class="fullscreen ? 'modal-order-tabs--fullscreen' : 'modal-order-tabs'"
  >
    <v-tabs v-model="tab" color="white" grow hide-slider>
      <v-tab class="profile-tab-label">Buscar cliente</v-tab>
      <v-tab class="profile-tab-label">Escanear codigo qr</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SearchUserForm
          :open="open"
          ref="searchUserForm"
          @orderAnonymus="$emit('orderAnonymus')"
          @order="$emit('order', $event)"
          @cancel="$emit('cancel')"
        ></SearchUserForm>
      </v-tab-item>

      <v-tab-item>
        <QrCamera @order="$emit('order', $event)" :turn-camera="cameraState"></QrCamera>
        <v-btn flat color="secondary" class="btn-cancel" @click="$emit('cancel')">Cancelar</v-btn>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>

<script>
import SearchUserForm from "@/components/forms/SearchUserForm";
import QrCamera from "@/components/generals/QrCamera";

export default {
  name: "TakeOrderModal",
  components: { SearchUserForm, QrCamera },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    open(val) {
      this.displayModal = val;
      this.cameraState = val && this.tab === 1;
    },
    tab(val) {
      this.cameraState = val === 1;
    }
  },
  data() {
    return {
      cameraState: this.open,
      displayModal: this.open,
      fullscreen: false,
      tab: 0
    };
  },
  mounted() {
    this.fullscreen = window.matchMedia("(max-width: 500px)").matches;
  },
  methods: {
    updateModal() {
      this.displayModal = this.open;
    },
    setProcessing(val) {
      this.$refs.searchUserForm.processing = val;
    }
  }
};
</script>
